import "./css/reset.css";
import "./css/style.css";
import * as THREE from "three";

{
  const familyArray = ["F", "A", "M", "I", "L", "Y"];
  const dateArray = [
    2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015,
    2016,
  ];

  const familyWriter = () => {};

  const datePicker = () => {
    const getDatesDiv = document.querySelector(`.date-picker-dates`);

    dateArray.forEach((date) => {
      let calcSize =
        ((0.1 * (dateArray.indexOf(date) + 1) * 10) / dateArray.length) * 2;

      if (dateArray.indexOf(date) + 1 > Math.ceil(dateArray.length / 2)) {
        calcSize =
          ((0.1 *
            (dateArray.indexOf(date) +
              1 -
              (dateArray.indexOf(date) + 1 - Math.ceil(dateArray.length / 2)) *
                2) *
            10) /
            dateArray.length) *
          2;
      }

      console.log(dateArray.indexOf(date));

      if (dateArray.indexOf(date) + 1 == Math.ceil(dateArray.length / 2)) {
        getDatesDiv.innerHTML += `<div class="date date-middle" style="transform: scale(${calcSize})">${date}</div>`;
      } else {
        getDatesDiv.innerHTML += `<div class="date" style="transform: scale(${calcSize})">${date}</div>`;
      }
    });
  };

  const datePickerSelect = () => {
    const getDates = document.querySelectorAll(`.date`);
    console.log(getDates);

    const getDatePicker = document.querySelector(`.date-picker-dates`);

    getDates.forEach((date) => {
      date.addEventListener("click", function () {
        const dateNumber = parseInt(date.innerHTML);
        const getDateMiddle = document.querySelector(`.date-middle`);
        const dateMiddleNumber = parseInt(getDateMiddle.innerHTML);

        getDates.forEach((newDate) => {
          const updatedDate =
            parseInt(newDate.innerHTML) + (dateNumber - dateMiddleNumber);

          newDate.innerHTML = updatedDate;

          if (updatedDate > 2016 || updatedDate < 2004) {
            newDate.style.color = "#a3a3a3";
            newDate.style.pointerEvents = "none";
          }

          if (updatedDate <= 2016 && updatedDate >= 2004) {
            newDate.style.pointerEvents = "auto";

            if (newDate.classList.contains(`date-middle`)) {
              newDate.style.color = "#e9e9e9";
            } else {
              newDate.style.color = "black";
            }
          }
        });
      });
    });
  };

  const blotterText = () => {
    let text;
    let material;

    console.log(window.innerWidth);

    if (window.innerWidth < 600) {
      text = new Blotter.Text("FAMILY", {
        family: "'EB Garamond', serif",
        size: 90,
        fill: "#171717",
      });

      material = new Blotter.FliesMaterial();

      material.uniforms.uSpeed.value = 0.25;
      material.uniforms.uPointCellWidth.value = 0.02;
      material.uniforms.uPointRadius.value = 0.7;
      material.uniforms.uSpeed.value = 5;
      // material.uniforms.uDodge.value = 1;
    } else if (window.innerWidth < 1000) {
      text = new Blotter.Text("FAMILY", {
        family: "'EB Garamond', serif",
        size: 130,
        fill: "#171717",
      });

      material = new Blotter.FliesMaterial();

      material.uniforms.uSpeed.value = 0.25;
      material.uniforms.uPointCellWidth.value = 0.015;
      material.uniforms.uPointRadius.value = 0.7;
      material.uniforms.uSpeed.value = 5;
      // material.uniforms.uDodge.value = 1;
    } else {
      text = new Blotter.Text("FAMILY", {
        family: "'EB Garamond', serif",
        size: 220,
        fill: "#171717",
      });

      material = new Blotter.FliesMaterial();

      material.uniforms.uSpeed.value = 0.25;
      material.uniforms.uPointCellWidth.value = 0.01;
      material.uniforms.uPointRadius.value = 0.8;
      material.uniforms.uSpeed.value = 5;
      // material.uniforms.uDodge.value = 1;
    }

    // BLOTTER - Example 2

    var blotter = new Blotter(material, {
      texts: text,
    });

    var elem = document.getElementById("distortion-text");
    var scope = blotter.forText(text);

    scope.appendTo(elem);
  };

  // window.addEventListener("resize", blotterText);

  const hiThere = () => {
    const headerYearDiv = document.querySelector(`.header-year-div`);
    const headerYearInner = document.querySelector(`.header-year-inner`);

    const cancelPointerEvent = () => {
      headerYearDiv.style.pointerEvents = "none";
      headerYearInner.classList.remove(`header-year-hover`);
      headerYearInner.classList.add(`header-year-leave`);

      setTimeout(() => {
        headerYearDiv.style.pointerEvents = "auto";
      }, 1001);
    };

    const activatePointerEvent = () => {
      headerYearInner.classList.remove(`header-year-leave`);
      headerYearInner.classList.add(`header-year-hover`);
    };

    headerYearDiv.addEventListener("mouseover", activatePointerEvent);
    headerYearDiv.addEventListener("mouseleave", cancelPointerEvent);
  };

  const setupGalleryImages = () => {
    const galleryImages = document.querySelector(`.gallery-images`);

    const newImg = document.createElement("div");
    galleryImages.appendChild(newImg);

    // newImg.style.width = `100px`;
    // newImg.style.height = `100px`;
    // newImg.style.backgroundImage = "url('../assets/images/header-img-2.jpg')";
  };

  const changeBgImage = () => {
    const bgHeader = document.querySelector(`.background-header`);
    if (window.innerHeight > window.innerWidth) {
      console.log("mobile");
      bgHeader.classList.add(`header-mobile`);
    }
  };

  const init = () => {
    console.log("hi");

    blotterText();
    datePicker();
    datePickerSelect();
    hiThere();
    setupGalleryImages();

    changeBgImage();
  };

  init();
}
